<template>
  <div class="container">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
      
      </div> -->

      <div class="player listmain type-f3d f3d game-f3d game-f3d-1zh">
        <div class="data">
          <h3>一字</h3>
          <ul>
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="(item,index) in rowsData[0].arr" :key="index">
              <ol class="td_name">
                <div class="greenball" :class="'b'+item.label">{{item.label}}</div>
              </ol>
              <ol class="td_rate">{{item.Odds}}</ol>
              <ol class="td_cash">
                <input v-model="item.money" @click.stop="showOrHideCheck($event, item)" type="number">
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      groupnames:'1z',
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [{ title: "一字", arr: [] }],
      labelArr: [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
    };
  },
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 一字
        let arr1 = this.ddFilterData1(
          resAArr,
          [3316, 3318, 3320, 3322, 3324, 3317, 3319, 3321, 3323, 3325],
          this.labelArr
        );
        let arr2=[]
        arr1.forEach(item=>{
          let obj={
            ResultID:item.ResultID,
            label:item.label
          }
          arr2.push(obj)
        })
        console.log(arr2,161)
        this.rowsData[0].arr = arr1;

        this.$forceUpdate();
      },
    },
  },

  created() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style  scoped>
.listmain .data > ul > li {
  width: 20%;
}
</style>